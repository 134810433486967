import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'ColorsCreate',
    data() {
        return {
            color: {
                name: null,
                code: '#ff0000'
            },
            fileAudio: null
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createColor() {
            const self = this
            let formData = new FormData()

            if(self.color.name) formData.append('name', self.color.name)
            if(self.color.code) formData.append('code', self.color.code)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/colors',
                method: 'POST',
                data: formData
            }).then(function() {
                self.$router.push({ name: 'admin.colors' })

                self.notifySuccess()
            })
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        }
    }
}